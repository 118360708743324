// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conocenos-js": () => import("./../../../src/pages/conocenos.js" /* webpackChunkName: "component---src-pages-conocenos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-adiciones-js": () => import("./../../../src/pages/menu/adiciones.js" /* webpackChunkName: "component---src-pages-menu-adiciones-js" */),
  "component---src-pages-menu-carnes-js": () => import("./../../../src/pages/menu/carnes.js" /* webpackChunkName: "component---src-pages-menu-carnes-js" */),
  "component---src-pages-menu-especiales-js": () => import("./../../../src/pages/menu/especiales.js" /* webpackChunkName: "component---src-pages-menu-especiales-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menu-pollos-js": () => import("./../../../src/pages/menu/pollos.js" /* webpackChunkName: "component---src-pages-menu-pollos-js" */),
  "component---src-pages-menu-sopas-js": () => import("./../../../src/pages/menu/sopas.js" /* webpackChunkName: "component---src-pages-menu-sopas-js" */),
  "component---src-pages-pedidos-js": () => import("./../../../src/pages/pedidos.js" /* webpackChunkName: "component---src-pages-pedidos-js" */),
  "component---src-pages-restaurantes-js": () => import("./../../../src/pages/restaurantes.js" /* webpackChunkName: "component---src-pages-restaurantes-js" */)
}

